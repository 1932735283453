<template>
  <div :class="$style.PWFieldWrapper">
    <div
      :class="[
        $style.PWInput,
        state.isFocused && $style.PWInputFocus,
        isError && $style.PWInputError,
        disabled && $style.PWInputDisabled,
      ]"
    >
      <span
        :class="[
          $style.PWInputPlaceholder,
          placeholderToUp && $style.PWInputPlaceholderToUp,
        ]"
      >
        {{ placeholder }}
      </span>
      <input
        ref="input"
        :type="type"
        :autocomplete="autocomplete"
        :value="modelValue"
        :disabled="disabled"
        :required="required"
        placeholder=""
        :class="$style.PWInputArea"
        :maxlength="maxLength"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        @keypress="handleKeypress"
      >
      <span v-if="append" :class="$style.append">
        {{ append }}
      </span>
    </div>
    <div
      v-if="$slots.default"
      :class="[
        $style.PWFieldTip,
        isError && $style.PWFieldTipError,
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">

// props
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: null
  },
  autocomplete: {
    type: String,
    default: 'off'
  },
  disabled: Boolean,
  required: {
    type: Boolean,
    default: undefined
  },
  isError: {
    type: Boolean,
    default: undefined
  },
  maxLength: {
    type: [Number, String],
    default: Infinity
  },
  append: {
    type: String,
    default: ''
  }
})

// emits
const emit = defineEmits(['blur', 'update:modelValue', 'keypress'])

// data
const state = reactive({
  isFocused: false
})

// refs
const inputRef = ref<HTMLElement>()

// computed
const valueIsNotEmpty = computed(() => {
  return typeof props.modelValue === 'string' && props.modelValue.length > 0
})

const placeholderToUp = computed(() => {
  return valueIsNotEmpty.value || state.isFocused
})

// methods
const focus = () => {
  inputRef.value?.focus()
}
const handleFocus = () => {
  state.isFocused = true
}
const handleBlur = () => {
  state.isFocused = false
  emit('blur')
}
const handleInput = (ev: any) => {
  const { target } = ev
  emit('update:modelValue', target.value)
}
const handleKeypress = (ev: any) => {
  emit('keypress', ev)
}

// expose
defineExpose({
  focus
})
</script>

<style module lang="scss">
@use 'assets/scss/mixins';

.PWFieldWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.PWFieldTip {
  padding: 0 14px;
  color: var(--secondary);

  @include mixins.caption;

  &.PWFieldTipError {
    color: var(--main-red);
  }
}

.PWInput {
  position: relative;
  display: inline-flex;
  width: 100%;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  background-color: var(--white);
  box-shadow: inset 0 0 0 1px var(--grey-200);

  &:hover:not(.PWInputFocus):not(.PWInputError):not(.PWInputDisabled) {
    box-shadow: inset 0 0 0 1px var(--grey-900);
  }

  &.PWInputFocus {
    box-shadow: inset 0 0 0 2px var(--main-blue);
  }

  &.PWInputError {
    box-shadow: inset 0 0 0 1px var(--main-red);

    .PWInputPlaceholder {
      color: var(--main-red);
    }
  }
}

.PWInput.PWInputDisabled {
  box-shadow: inset 0 0 0 1px var(--grey-200);
  background-color: var(--grey-100);

  .PWInputArea,
  .PWInputPlaceholder {
    color: var(--disabled);
    -webkit-text-fill-color: var(--disabled);
    opacity: 1;
  }
}

.PWInputArea {
  all: unset;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
  padding: 24px 12px 8px 12px;
  color: var(--primary);
  background-color: transparent;

  @include mixins.body1;
}

.PWInputPlaceholder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 12px;
  display: -webkit-box !important;
  max-width: calc(100% - 32px);
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1 !important;
  transform: translateY(20px) scale(1);
  transform-origin: top left;
  transition: transform 0.3s;
  color: var(--secondary);

  @include mixins.body1;
}

.PWInputFocus {
  box-shadow: inset 0 0 0 1px currentColor;
}

.PWInputPlaceholderToUp {
  transform: translateY(9px) scale(0.75);
}

.append {
  padding: 24px 12px 8px 0;
}
</style>
